body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background 0.4s ease-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/helvetica/HelveticaNeueLTArabic-Light.eot');
  src: url('./assets/fonts/helvetica/HelveticaNeueLTArabic-Light.eot#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/helvetica/HelveticaNeueLTArabic-Light.woff')
      format('woff'),
    url('./assets/fonts/helvetica/HelveticaNeueLTArabic-Light.ttf')
      format('truetype'),
    url('./assets/fonts/helvetica/HelveticaNeueLTArabic-Light.svg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

html[dir='rtl'] body {
  direction: rtl;
  font-family: Helvetica, sans-serif;
  text-align: right;
  line-height: 15px;
  font-weight: bold;
}
